import { HStack, Tag, Text } from '@chakra-ui/react'
import { ASSET_TYPE_TO_MATCH_TYPE, MatchType } from '../../utils/reports/match_utils'

const FONT_SIZE = 12

type MatchScoreDetailProps = {
  match: any
}

const setScore = (match, matchType: MatchType) => {
  return ASSET_TYPE_TO_MATCH_TYPE[match.asset.type] == matchType
    ? match.similarity_score
    : 0.0
}

export const MatchScoreText = (props: MatchScoreDetailProps) => {
  const { match } = props

  if (!match) return <></>

  const imageScore = match.image_score
    ? match.image_score
    : setScore(match, MatchType.Image)
  const keywordScore = match.keywords_score
    ? match.keywords_score
    : setScore(match, MatchType.Keyword)
  const logoScore = match.logo_score
    ? match.logo_score
    : setScore(match, MatchType.Logo)

  return (
    <HStack>
      {imageScore > 0 && (
        <>
          <Text fontSize={FONT_SIZE}>Image</Text>

          <Tag fontSize={12} width="fit-content">
            {imageScore.toFixed(2).toString()}
          </Tag>
        </>
      )}

      {keywordScore > 0 && (
        <>
          <Text fontSize={FONT_SIZE}>Keyword</Text>

          <Tag fontSize={12} width="fit-content">
            {keywordScore.toFixed(2).toString()}
          </Tag>
        </>
      )}

      {logoScore > 0 && (
        <>
          <Text fontSize={FONT_SIZE}>Logo</Text>

          <Tag fontSize={12} width="fit-content">
            {logoScore.toFixed(2).toString()}
          </Tag>
        </>
      )}
    </HStack>
  )
}
