import { Divider, Flex, Heading, Text } from '@chakra-ui/react'
import {
  getMarketplacesForCollection,
  Marketplace,
} from '../../utils/reports/marketplace_utils'
import { MatchingKeywordsText } from '../report_detail/matching_keywords_texts'
import { MatchScoreText } from '../report_detail/match_score_text'
import {
  assetTypeNotCollectionRelated,
  extractTitleFromNft,
  extractCurrentPriceFromNft,
  hasOriginalImage,
  mapFlaggedContentTypeToReadable,
  originalCollectionHasContractAddress,
  shouldShowRightColumn,
} from './counterfeit_detail'
import DetailRow from './detail_row'
import MarketplaceLink from './marketplace_link'
import { convertCryptoToDollars } from '../../utils/index'

export default function MatchFlaggedContentSection({
  selectedReport,
  selectedMatch,
  assetSrcs,
}) {
  return (
    <>
      <Heading as="h4" fontSize={13} maxW="75%" noOfLines={1} size="sm">
        {selectedMatch?.flagged_nft ? 'NFT' : 'Collection Image'}
      </Heading>

      <Divider
        maxWidth={
          shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
          originalCollectionHasContractAddress(selectedReport)
            ? '90%'
            : '60%'
        }
        padding={1}
      />

      <DetailRow
        leftDetail={
          <Text fontSize={13}>
            {selectedMatch?.flagged_nft
              ? extractTitleFromNft(selectedMatch?.flagged_nft)
              : mapFlaggedContentTypeToReadable(selectedMatch?.flagged_content_type)}
          </Text>
        }
        rightDetail={
          shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
          hasOriginalImage(assetSrcs) &&
          originalCollectionHasContractAddress(selectedReport) && (
            <Text fontSize={13}>{extractTitleFromNft(selectedMatch?.asset.nft)}</Text>
          )
        }
        title={selectedMatch?.flagged_nft ? 'NFT Name' : 'Collection Image Type'}
      />

      <Divider
        maxWidth={
          shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
          originalCollectionHasContractAddress(selectedReport)
            ? '90%'
            : '60%'
        }
        padding={1}
      />

      <DetailRow
        leftDetail={<MatchScoreText match={selectedMatch} />}
        title="NFT Match Scores"
      />

      <Divider
        maxWidth={
          shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
          originalCollectionHasContractAddress(selectedReport)
            ? '90%'
            : '60%'
        }
        padding={1}
      />

      {selectedMatch?.flagged_nft && (
        <DetailRow
          leftDetail={<MatchingKeywordsText match={selectedMatch} />}
          title="Matching Keywords"
        />
      )}

      <Divider
        maxWidth={
          shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
          originalCollectionHasContractAddress(selectedReport)
            ? '90%'
            : '60%'
        }
        padding={1}
      />

      <DetailRow
        leftDetail={
          <Text fontSize={13}>
            {convertCryptoToDollars(
              extractCurrentPriceFromNft(selectedMatch?.flagged_nft),
              'eth',
            )}
          </Text>
        }
        rightDetail={
          shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
          hasOriginalImage(assetSrcs) &&
          originalCollectionHasContractAddress(selectedReport) && (
            <Text fontSize={13}>N/A</Text>
          )
        }
        title="Total Listing Price"
      />

      <Divider
        maxWidth={
          shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
          originalCollectionHasContractAddress(selectedReport)
            ? '90%'
            : '60%'
        }
        padding={1}
      />

      {selectedMatch?.flagged_nft && (
        <DetailRow
          leftDetail={
            <Flex>
              {getMarketplacesForCollection(selectedReport.flagged_collection).map(
                (marketplace: Marketplace) => (
                  <MarketplaceLink
                    isCollection={false}
                    isFlagged={true}
                    key={marketplace}
                    marketplace={marketplace}
                    selectedMatch={selectedMatch}
                    selectedReport={selectedReport}
                  />
                ),
              )}
            </Flex>
          }
          rightDetail={
            shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
            hasOriginalImage(assetSrcs) &&
            originalCollectionHasContractAddress(selectedReport) &&
            assetTypeNotCollectionRelated(selectedMatch?.asset) && (
              <Flex>
                {getMarketplacesForCollection(selectedReport.original_collection).map(
                  (marketplace) => (
                    <MarketplaceLink
                      isCollection={false}
                      isFlagged={false}
                      key={marketplace}
                      marketplace={marketplace}
                      selectedMatch={selectedMatch}
                      selectedReport={selectedReport}
                    />
                  ),
                )}
              </Flex>
            )
          }
          title="NFT Links"
        />
      )}

      <Divider
        maxWidth={
          shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
          originalCollectionHasContractAddress(selectedReport)
            ? '90%'
            : '60%'
        }
        padding={1}
      />

      <br />
    </>
  )
}
