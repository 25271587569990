import { VStack, Flex, Spinner, Text } from '@chakra-ui/react'
import { useGetMarketplaceMatchNumbersQuery } from '../../generated/graphql'
import {
  getMarketplacesForCollection,
  Marketplace,
} from '../../utils/reports/marketplace_utils'
import MarketplaceLink from './marketplace_link'

export default function CollectionMarketplaceLinksWithMatchNumbers({
  selectedReport,
  selectedMatch,
}) {
  const { data, loading, error } = useGetMarketplaceMatchNumbersQuery({
    variables: {
      id: selectedReport.id,
    },
  })
  const matchCount =
    selectedReport.collection_reports_to_matches_aggregate.aggregate.count

  return (
    <VStack alignItems="left">
      <Flex alignItems="center">
        <VStack alignItems="left">
          {getMarketplacesForCollection(selectedReport.flagged_collection).map(
            (marketplace: Marketplace) => {
              if (marketplace == Marketplace.Magiceden) {
                return null
              }
              return (
                <Flex alignItems="center">
                  <MarketplaceLink
                    isCollection={true}
                    isFlagged={true}
                    marketplace={marketplace}
                    selectedMatch={selectedMatch}
                    selectedReport={selectedReport}
                    showSharedStorefront={true}
                  />

                  {!loading && data && (
                    <Text fontSize="13" marginLeft="2">
                      {extractRemainingMatchCountForMarketplace(
                        marketplace,
                        data,
                        matchCount,
                      )}{' '}
                      matches remaining
                    </Text>
                  )}
                </Flex>
              )
            },
          )}
        </VStack>

        {loading && <Spinner marginLeft={4} />}
      </Flex>
    </VStack>
  )
}

function extractRemainingMatchCountForMarketplace(
  marketplace: Marketplace,
  data: any,
  matchCount: number,
): number {
  const report = data.collection_reports[0]
  switch (marketplace) {
    case Marketplace.Opensea:
      return matchCount - report.count_delisted_matches_for_opensea.aggregate.count
    case Marketplace.Rarible:
      return matchCount - report.count_delisted_matches_for_rarible.aggregate.count
    case Marketplace.X2Y2:
      return matchCount - report.count_delisted_matches_for_x2y2.aggregate.count
    case Marketplace.Jungle:
      return matchCount - report.count_delisted_matches_for_jungle.aggregate.count
    case Marketplace.Magiceden:
      return matchCount - report.count_delisted_matches_for_magiceden.aggregate.count
    case Marketplace.Looksrare:
      return matchCount - report.count_delisted_matches_for_looksrare.aggregate.count
  }
}
