import { Box, Checkbox, Flex, Image, SimpleGrid } from '@chakra-ui/react'
import { useSelectedReportStatus } from '../../pages/reports'
import { ReportStatus } from '../../utils/constants'

type MatchCarouselProps = {
  srcs: Array<{
    image_url: string
    backup_image_url?: string
    source_url?: string
  }>
  handleUpdateSelectedMatch: any
  selectedMatchIndex: number
  selectedNfts: Set<number>
  handleUpdateSelectedNfts: any
}

const MatchSelector = (props: MatchCarouselProps) => {
  const {
    srcs,
    handleUpdateSelectedMatch,
    selectedMatchIndex,
    selectedNfts,
    handleUpdateSelectedNfts,
  } = props
  const [selectedReportStatus, setSelectedReportStatus] = useSelectedReportStatus()

  return (
    <Flex justifyContent="leading">
      <SimpleGrid columns={{ sm: 5, md: 10 }}>
        {srcs
          ?.filter((src) => src)
          .map((src, index) => {
            return (
              <Box
                style={{
                  width: '50px',
                  height: '50px',
                  margin: '4px',
                }}
              >
                {selectedReportStatus == ReportStatus.NEEDS_REVIEW && (
                  <Checkbox
                    boxShadow="l"
                    isChecked={selectedNfts.has(index)}
                    margin="1"
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleUpdateSelectedNfts(null, index, null)
                      } else {
                        handleUpdateSelectedNfts(null, null, index)
                      }
                    }}
                    position="absolute"
                    zIndex="10"
                  />
                )}

                <Image
                  alt="1"
                  boxShadow={selectedMatchIndex == index ? 'outline' : 'l'}
                  cursor="pointer"
                  fallbackSrc={src.backup_image_url}
                  height="50px"
                  key={index}
                  objectFit="cover"
                  onClick={() => handleUpdateSelectedMatch(index)}
                  opacity={selectedMatchIndex == index ? 1 : 0.2}
                  rounded={'lg'}
                  src={src.image_url}
                  style={
                    selectedMatchIndex == index
                      ? {
                          boxShadow: '0 0 0 4px blue.600',
                        }
                      : {}
                  }
                  width="50px"
                />
              </Box>
            )
          })}
      </SimpleGrid>
    </Flex>
  )
}

export default MatchSelector
