import { Text, VStack } from '@chakra-ui/react'
import { extractNftTextData } from '../../utils/reports/match_utils'

// https://stackoverflow.com/questions/1059559/split-strings-into-words-with-multiple-word-boundary-delimiters/1059601#1059601
const GENERIC_WORD_BOUNDARY_DELIMITER = '[\\W+|_]'

export default function MatchingKeywordsTexts({ selectedReport }) {
  const matches = selectedReport.collection_reports_to_matches
    .map((collectionReportToMatch) => collectionReportToMatch.match)
    .filter((match) => match.matched_keywords)
  return (
    <VStack>
      {matches.slice(0, Math.min(matches.length, 3)).map((match) => {
        return <MatchingKeywordsText match={match} />
      })}
    </VStack>
  )
}

export function MatchingKeywordsText({ match }) {
  if (!match.matched_keywords) {
    return null
  }
  const keywordMatches = trimChars(match.matched_keywords, '(', ')')
    ?.split(',')
    .map((keyword) => keyword.trim())
  const flaggedNft = match.flagged_nft
  if (keywordMatches == null || keywordMatches.length == 0 || keywordMatches[0] == '') {
    return null
  }
  return (
    <Text
      dangerouslySetInnerHTML={{
        __html: getMatchedKeywordText(
          keywordMatches[0],
          extractNftTextData(flaggedNft),
        ),
      }}
      fontSize={13}
      whiteSpace="initial"
      width="300px"
    ></Text>
  )
}

// TODO: use keyword_matches only when backfill completes
const getMatchedKeywordText = (keyword: string, nftTextData: string[]) => {
  if (!keyword) {
    return
  }
  // TODO show all keyword matches
  for (const text of nftTextData) {
    if (text == null) {
      continue
    }
    let regex = null
    try {
      regex = new RegExp(keyword.split(' ').join(GENERIC_WORD_BOUNDARY_DELIMITER), 'i')
    } catch (err) {
      return ''
    }
    const match = regex.exec(text)
    if (!match) {
      continue
    }
    const startChar = Math.max(0, match.index - 30)
    const endChar = Math.min(text.length, match.index + 30 + match[0].length)
    return (
      (startChar == 0 ? '' : '...') +
      text.substring(startChar, match.index).trimStart() +
      "<span style='background-color:#ffcc33;'>" +
      text.substring(match.index, match.index + match[0].length) +
      '</span>' +
      text.substring(match.index + match[0].length, endChar).trimEnd() +
      (endChar >= text.length ? '' : '...')
    )
  }
  return 'no matches'
}

// https://stackoverflow.com/questions/26156292/trim-specific-character-from-a-string
// We used to format matched_keywords such as '(Snoop, Dogg)'
function trimChars(str, ch1, ch2) {
  let start = 0,
    end = str.length

  while (start < end && (str[start] === ch1 || str[start] === ch2)) ++start

  while (end > start && (str[end - 1] === ch1 || str[end - 1] === ch2)) --end

  return start > 0 || end < str.length ? str.substring(start, end) : str
}
