import {
  extractMarketplaceData,
  getMarketplaceLinkForCollection,
  getMarketplaceLinkForNft,
  isNftDelistedOnMarketplace,
  isNftInSharedStorefrontCollection,
  Marketplace,
} from '../../utils/reports/marketplace_utils'
import MarketplaceButton from '../shared/marketplace_button'

export default function MarketplaceLink({
  marketplace,
  selectedReport,
  selectedMatch,
  isFlagged,
  isCollection,
  showSharedStorefront = false,
}) {
  if (marketplace == Marketplace.Magiceden) {
    // TODO: implement Magiceden links
    return null
  }
  let link
  if (isCollection) {
    link = getMarketplaceLinkForCollection(
      marketplace,
      selectedReport,
      selectedMatch,
      isFlagged,
    )
  } else {
    link = getMarketplaceLinkForNft(
      marketplace,
      selectedReport,
      selectedMatch,
      isFlagged,
    )
  }
  const nft = isFlagged ? selectedMatch?.flagged_nft : selectedMatch?.asset?.nft
  const collection = isFlagged
    ? selectedReport.flagged_collection
    : selectedReport.original_collection
  let isDelisted = extractMarketplaceData(collection, marketplace)?.delist_detected_at
  if (!isCollection) {
    isDelisted = isDelisted || isNftDelistedOnMarketplace(nft, marketplace)
  }

  const isSharedStorefront =
    !isDelisted &&
    isCollection &&
    isFlagged &&
    isNftInSharedStorefrontCollection(
      selectedReport,
      selectedMatch?.flagged_nft,
      marketplace,
    )

  return (
    <MarketplaceButton
      isDelisted={isDelisted}
      isSharedStorefront={isSharedStorefront}
      link={link}
      marketplace={marketplace}
    />
  )
}
