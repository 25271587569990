import { Box, Icon, Image, Link, Tooltip } from '@chakra-ui/react'
import { IoRemoveCircle } from 'react-icons/io5'
import { AiFillShop } from 'react-icons/ai'
import {
  getMarketplaceLogoUrl,
  Marketplace,
} from '../../utils/reports/marketplace_utils'
import { DOPPEL_ENCRYPT_GREEN } from '../../utils/style'

type MarketplaceButtonProps = {
  marketplace: Marketplace
  link: string
  isDelisted?: boolean
  isSharedStorefront?: boolean
}

const MarketplaceButton = (props: MarketplaceButtonProps) => {
  const { marketplace, link, isDelisted = false, isSharedStorefront = false } = props

  let optionalDecorator = null
  if (isDelisted) {
    optionalDecorator = (
      <Icon
        as={IoRemoveCircle}
        color={DOPPEL_ENCRYPT_GREEN}
        height="15px"
        margin="22px"
        position="absolute"
        width="15px"
        zIndex="10"
      />
    )
  } else if (isSharedStorefront) {
    optionalDecorator = (
      <Icon
        as={AiFillShop}
        color={'#DAB46C'}
        height="15px"
        margin="22px"
        position="absolute"
        width="15px"
        zIndex="10"
      />
    )
  }

  return (
    <Box height="30px" margin="1" width="30px">
      <Tooltip label={marketplace}>
        <Link href={link} isExternal>
          {optionalDecorator}

          <Image
            _hover={{
              boxShadow: 'dark-lg',
              cursor: 'pointer',
            }}
            boxShadow={'lg'}
            height="30px"
            opacity={isDelisted ? 0.3 : 1}
            rounded={'lg'}
            src={getMarketplaceLogoUrl(marketplace)}
            style={isDelisted ? { filter: 'grayscale(100%)' } : {}}
            width="30px"
          />
        </Link>
      </Tooltip>
    </Box>
  )
}

export default MarketplaceButton
