import { Tag, Text } from '@chakra-ui/react'

type DoppelScoreProps = {
  score: number
  children?: React.ReactNode
}

export const colorForDoppelScore = (score) => {
  if (score) {
    if (score > 0.7) {
      return '#DA6C6C'
    } else if (score > 0.4) {
      return '#DAB46C'
    } else {
      return '#8BB458'
    }
  } else {
    return '#8BB458'
  }
}

const DoppelScore = (props: DoppelScoreProps) => {
  const { children, score } = props

  if (!score) {
    return <Text />
  }

  const innerComponent = children || score

  return (
    <Tag
      bgColor={colorForDoppelScore(score)}
      color={'white'}
      fontSize={12}
      width="fit-content"
    >
      {innerComponent}
    </Tag>
  )
}

export default DoppelScore
