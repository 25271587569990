import { Flex, HStack, Text, Button, Collapse, Tag, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { GetReportStateChangesQuery } from '../../generated/graphql'
import { ReportType, getStatusLabel } from '../../utils/constants'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import { getTimestampDisplay } from '../../utils/time'
import { isInternalStateChangeValue } from '../shared/change_history'
import { DOPPEL_SECURE } from '../../utils/style'

export function ReportHistory({
  stateChanges,
}: {
  stateChanges: GetReportStateChangesQuery
}) {
  const [showHistory, setShowHistory] = useState(false)
  const [isEmployeeView] = useIsEmployeeView()

  if (!stateChanges) {
    return <Text fontSize={13}></Text>
  }

  let { collection_report_state_changes: reportStateChanges } = stateChanges

  if (!isEmployeeView) {
    // TODO: properly gate these changes by creating different hasura roles
    reportStateChanges = reportStateChanges.filter((stateChange) => {
      return (
        !isInternalStateChangeValue(stateChange.new_state) &&
        !isInternalStateChangeValue(stateChange.previous_state)
      )
    })
  }

  if (reportStateChanges.length === 0) {
    return <Text fontSize={13}></Text>
  }

  return (
    <Flex
      direction={'column'}
      marginTop={reportStateChanges.length > 1 ? '4px' : '-12px'}
      width="100%"
    >
      {reportStateChanges.length > 1 && (
        <Button
          color={'teal'}
          fontSize={12}
          justifyContent={'flex-start'}
          onClick={() => setShowHistory(!showHistory)}
          variant="link"
        >
          {showHistory ? 'Hide History' : 'Show Full History'}
        </Button>
      )}

      {reportStateChanges.length > 0 && (
        // Always show the first change whether full history is toggled or not
        <ReportStatusChange stateChange={reportStateChanges[0]} />
      )}

      {reportStateChanges.slice(1).map((stateChange) => {
        return (
          // show the rest of the changes when full history is toggled on
          <Collapse in={showHistory} key={'state-change-' + stateChange.id}>
            <ReportStatusChange stateChange={stateChange} />
          </Collapse>
        )
      })}
    </Flex>
  )
}

function ReportStatusChange({ stateChange }) {
  return (
    <Flex direction={'column'} flexGrow={0} marginBottom={4} marginTop={4}>
      <VStack align={'left'}>
        <HStack align="center" spacing={4}>
          {stateChange.user && (
            <Tag fontSize={12} height="fit-content" width="fit-content">
              {stateChange.user.name.split(' ')[0]}
            </Tag>
          )}

          <Text color={DOPPEL_SECURE} fontSize={12}>
            {getTimestampDisplay(stateChange.timestamp_of_change)}
          </Text>
        </HStack>

        <Flex>
          <HStack>
            <Text fontSize={12}>
              {getStatusLabel(stateChange.previous_state, ReportType.NFTS)}
            </Text>

            <Text fontSize={12}>{'\u2192'}</Text>

            <Text fontSize={12}>
              {getStatusLabel(stateChange.new_state, ReportType.NFTS)}
            </Text>
          </HStack>
        </Flex>

        {stateChange.reason_for_change && (
          <HStack>
            <Text fontSize={12}>{stateChange.reason_for_change}</Text>
          </HStack>
        )}
      </VStack>
    </Flex>
  )
}
