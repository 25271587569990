import { Flex } from '@chakra-ui/react'
import { useOrgID } from '../../hooks/id_token_claims'
import { isMarketplaceMatchCountEnabled } from '../../utils/feature_flags'
import {
  getMarketplacesForCollection,
  Marketplace,
} from '../../utils/reports/marketplace_utils'
import CollectionMarketplaceLinksWithMatchNumbers from './collection_marketplace_links_with_match_numbers'
import {
  hasOriginalImage,
  originalCollectionHasContractAddress,
  shouldShowRightColumn,
} from './counterfeit_detail'
import DetailRow from './detail_row'
import MarketplaceLink from './marketplace_link'

export default function CollectionMarketplaceLinks({
  selectedReport,
  selectedMatch,
  assetSrcs,
}) {
  const orgId = useOrgID()

  const leftDetail = isMarketplaceMatchCountEnabled(orgId) ? (
    <CollectionMarketplaceLinksWithMatchNumbers
      selectedMatch={selectedMatch}
      selectedReport={selectedReport}
    />
  ) : (
    <Flex>
      {getMarketplacesForCollection(selectedReport.flagged_collection).map(
        (marketplace: Marketplace) => (
          <MarketplaceLink
            isCollection={true}
            isFlagged={true}
            marketplace={marketplace}
            selectedMatch={selectedMatch}
            selectedReport={selectedReport}
          />
        ),
      )}
    </Flex>
  )

  return (
    <>
      <DetailRow
        leftDetail={leftDetail}
        rightDetail={
          shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
          hasOriginalImage(assetSrcs) &&
          originalCollectionHasContractAddress(selectedReport) && (
            <Flex>
              {getMarketplacesForCollection(selectedReport.original_collection).map(
                (marketplace: Marketplace) => (
                  <MarketplaceLink
                    isCollection={true}
                    isFlagged={false}
                    marketplace={marketplace}
                    selectedMatch={selectedMatch}
                    selectedReport={selectedReport}
                  />
                ),
              )}
            </Flex>
          )
        }
        title="Collection Links"
      />
    </>
  )
}
